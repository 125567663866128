@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
